.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  background-image: url("./Assets/Images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  overflow: none;
  scroll-behavior: none;
}
.App-Contact {
  margin-top: 100px;
  text-align: center;
}
.App-logo {
  margin-top: 10vh;
  pointer-events: none;
  padding: 0;
  border-radius: 25px;
  img {
    min-width: 250px;
    max-width: 800px;
  }
}

@media screen and (max-width: 980px) {
  .App-logo {
    pointer-events: none;
    padding: 0;
    border-radius: 25px;
    img {
      min-width: 250px;
    }
  }
}
